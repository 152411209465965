import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isAdmin: false,
    nav: [],
    category: [],
    years: [],
    areas: []
  },
  getters: {
  },
  mutations: {
    // 设置导航
    SET_NAV: (state, nav) => {
      state.nav = nav
    },
    // 设置分类
    SET_CATEGORY: (state, category) => {
      state.category = category
    },
    // 设置条件
    SET_YEARS: (state, years) => {
      state.years = years
    },
    // 设置用户
    SET_USER: (state, user) => {
      state.user = user
      state.isAdmin = user ? user.authorities.indexOf('ROLE_AUTOPARTS_ADMIN') >= 0 : false
    },
    // 设置所有地区
    SET_AREAS: (state, areas) => {
      state.areas = areas
    }
  },
  actions: {
  },
})
